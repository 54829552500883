// @ts-ignore
import type { Timestamp, DocumentReference } from 'firebase/firestore';
import type { IAuthProvider } from "./Auth";

export interface IUser {
  name: string;
  phone?: string;
  email?: string;
  role: UserType;
  photo?: string;
  provider?: IAuthProvider;
  id?: string;
  created: Timestamp;
  avatar_ref?: DocumentReference<IUserAvatar>;
}

export interface IUserID extends IUser {
  id: string;
}

export enum UserType {
  "customer" = "customer",
  "cashier" = "cashier",
}

export interface IUserAvatar {
  id?: string;
  created: Timestamp;
  verified: boolean;
}
